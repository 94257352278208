export function adjustColor(color, amount) {
  return "#" + color.replace(/^#/, "").replace(/../g, color => ("0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
}

export function arrayShuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

export function findSimilarJobs(currentJob, jobs, limit) {
  let similarJobs = []

  jobs.forEach(job => {
    if(job.companyName && currentJob.companyName &&
        job.companyName.toLowerCase() === currentJob.companyName.toLowerCase() && job.id != currentJob.id) {
      similarJobs.push(job)
    }
  })

  if(limit && limit > 0) {
    similarJobs = similarJobs.splice(0, limit)
  }
  return similarJobs
}